@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.Main-banner {
  height: 75vh;
  position: relative;
  z-index: -1;
  /* background-image: url(../images/background.jpg);
  background-size: cover; */
  background-size: cover;
}

.Banner-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 100%;
  text-align: center;
}

.text {
  font-size: 79px;
  color: #ff5722;
  margin-top: 200px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  text-shadow: 1px 1px 10px rgb(0, 0, 0);
}

.section {
  padding-top: 20px;
  margin: auto;
  height: 35vh;
  width: 80%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  margin-top: -50px;
  z-index: 1;
  border-radius: 10px;
  box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
}

.section-block {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.section-heading {
  margin: 0;
  margin-top: 5px;
  font-size: 25px;
}

.body-text {
  font-size: 18px;
  width: 90%;
  text-align: center;
}

.About-Section {
  width: 100%;
  margin: auto;
  background-color: rgb(255, 255, 255);
  background: linear-gradient(rgba(0, 0, 0, 0.562), rgba(0, 0, 0, 0.76)),
    url(../images/test.jpg);
  background-size: cover;
  text-align: start;
  padding: 100px 0px 100px 0;
}

.About-Body {
  font-size: 18px;
}

.About-Text-Section {
  width: 60%;
  margin: auto;
  text-align: start;
  color: white;
}

.line {
  width: 50px;
  height: 2.5px;
  background-color: rgb(255, 255, 255);
}

@media (max-width: 1000px) {
  .Banner-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 100%;
  }

  .text {
    font-size: 30px;
    color: #ff5722;
    margin-top: 100px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }

  .section {
    margin: auto;
    height: auto;
    width: 80%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    margin-top: 50px;
    z-index: 1;
    border-radius: 10px;
    box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .section-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  .image {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }

  .section-heading {
    margin: 0;
    margin-top: 5px;
  }

  .body-text {
    font-size: 14px;
    width: 90%;
  }

  .About-Section {
    width: 100%;
    margin: auto;
    background-color: red;
    background-position: -300px;
  }

  .About-Text-Section {
    width: 90%;
    margin: auto;
    text-align: start;
    color: white;
  }

  .line {
    width: 50px;
    height: 2.5px;
    background-color: rgb(255, 255, 255);
  }

  ul {
    padding-left: 25px;
  }

  .About-Body {
    font-size: 14px;
    padding-left: 10px;
  }
}
