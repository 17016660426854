.Contact-Form {
  margin: auto;
  margin-top: 100px;
  width: 60%;
  padding: 25px;
  margin-bottom: 11rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
}

.label {
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  color: rgb(2, 2, 2);
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.label-message {
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  color: rgb(2, 2, 2);
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.input {
  border-radius: 5px;
  border: none;
  padding: 15px;
  font-size: 16px;
  background-color: rgb(221, 219, 219);
  font-family: "Roboto", sans-serif;
}

.input-message {
  border-radius: 5px;
  border: none;
  padding: 15px;
  font-size: 16px;
  background-color: rgb(221, 219, 219);
  font-family: "Roboto", sans-serif;
}

.submitButton {
  display: flex;
  justify-content: flex-end;
}

.submit {
  display: flex;
  background-color: #1235f8;

  border: none;
  color: #e1faf9;
  text-transform: uppercase;
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 15px;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 60px;
  transition: 0.3s ease-in-out, color 0.3s ease-in-out;
  cursor: pointer;
}

.submit:hover {
  background-color: #ff5722;
}

/* MODEL */

.modal {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  width: 500px;
  padding: 25px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
}

.close {
  background-color: #ff5722;
  border: none;
  color: #e1faf9;
  text-transform: uppercase;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 60px;
  transition: 0.3s ease-in-out, color 0.3s ease-in-out;
  cursor: pointer;
}

@media (max-width: 800px) {
  .Contact-Form {
    margin: auto;
    margin-top: 100px;
    width: 75%;
    padding: 25px;
    margin-bottom: 11rem;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
  }

  .label {
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    color: rgb(2, 2, 2);
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
  }

  .label-message {
    font-size: 16px;
    font-weight: 700;
    margin-top: 40px;
    color: rgb(2, 2, 2);
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif;
  }

  .input {
    border-radius: 5px;
    border: none;
    padding: 10px;
    font-size: 14px;
    background-color: rgb(221, 219, 219);
    font-family: "Roboto", sans-serif;
  }

  .input-message {
    border-radius: 5px;
    border: none;
    padding: 10px;
    font-size: 14px;
    background-color: rgb(221, 219, 219);
    font-family: "Roboto", sans-serif;
  }

  .submitButton {
    display: flex;
    justify-content: flex-end;
  }

  .submit {
    display: flex;
    background-color: #1235f8;
    border: none;
    color: #e1faf9;
    text-transform: uppercase;
    border-radius: 5px;
    margin-top: 60px;
    margin-bottom: 0px;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 40px;
    transition: 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
  }

  .submit:hover {
    background-color: #000dc2bd;
  }

  /* MODEL */

  .modal {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    width: 80%;
    padding: 25px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    font-family: "Montserrat", sans-serif;

    box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
  }

  .close {
    background-color: #ff5722;
    border: none;
    color: #e1faf9;
    text-transform: uppercase;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 40px;
    transition: 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
  }
}
