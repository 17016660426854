.Footer {
  width: 100%;
  margin-bottom: 5px;
  box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
}

.Main-Footer {
  margin: auto;
  width: 70%;
  height: 100px;
  display: flex;
  box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
}

.Footer-Right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Footer-Left {
  padding-top: 5px;
  padding-left: 20px;
  width: 50%;
}

.footer-name {
  font-size: 16px;
  margin: 0;
  margin-bottom: 5px;
}

.footer-copy {
  margin: 0;
  font-size: 14px;
}

.Footer-Img {
  width: 150px;
}

.rights {
  text-align: center;
  font-size: 10px;
}

@media (max-width: 800px) {
  .Footer-Img {
    width: 80px;
    margin-right: 20px;
  }

  .rights {
    text-align: center;
    font-size: 6px;
  }

  .Main-Footer {
    margin: auto;
    width: 100%;
    height: 100px;
    display: flex;
    box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: -1px -36px 37px -48px rgba(0, 0, 0, 0.27);
  }

  .footer-name {
    font-size: 14px;
    margin: 0;
    margin-bottom: 5px;
  }

  .footer-copy {
    margin: 0;
    font-size: 10px;
  }
}
