@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.Main-banner {
  height: 75vh;
  position: relative;
  z-index: -1;
  background-size: cover;
}

.Banner-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 100%;
  text-align: center;
}

.text {
  font-size: 79px;
  color: #ff5722;
  margin-top: 200px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.Introduction {
  font-size: 20px;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  padding: 25px;
}

.Info {
  display: flex;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 80%;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.Research-Image {
  width: 450px;
  height: 600px;
  background-image: url(../images/Toddlers.jpg);
  background-size: cover;
  border-radius: 10px;
}

.Info-caption {
  text-align: center;
  width: 450px;
  height: auto;
  margin-left: 50px;
  font-family: "Montserrat", sans-serif;
}

.CTA {
  font-family: "Montserrat", sans-serif;
  margin-top: 35px;
  padding: 15px;
  width: 80%;
  text-align: center;
  border-radius: 20px;
  background-color: #000000;
  border: none;
}

.CTA:hover {
  background-color: #b83006;
}

.to-Contact {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 16px;
  text-transform: uppercase;
}

/* Counseling */

.Research-Image-Counseling {
  width: 600px;
  height: 600px;
  /* background-image: url(../images/Toddlers.jpg); */
  background-size: cover;
  border-radius: 10px;
  background-image: url(../images/Counseling.jpg);
}

.sectionTwo {
  padding-top: 10px;
  margin: auto;
  width: 80%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  margin-top: 50px;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0px 8px 42px -22px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 8px 42px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 8px 42px -22px rgba(0, 0, 0, 0.75);
}

@media (max-width: 1000px) {
  .Banner-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 100%;
  }

  .text {
    font-size: 30px;
    color: #ff5722;
    margin-top: 100px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }

  .Info {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 25px;
    width: 80%;
    justify-content: center;
    align-items: center;
    padding: 25px;
  }

  .Research-Image {
    width: 80%;
    height: 300px;
    background-image: url(../images/Toddlers.jpg);
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .Info-caption {
    text-align: center;
    width: 80%;
    margin-left: 0px;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 50px;
  }

  .info-heading {
    font-size: 20px;
  }

  .info-copy {
    margin-top: 40px;
    font-size: 14px;
  }

  .CTA {
    font-family: "Montserrat", sans-serif;
    margin-top: 25px;
    padding: 10px;
    width: 80%;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 16px;
    border-radius: 20px;
    background-color: #000000;
    text-transform: uppercase;
  }

  .CTA:hover {
    background-color: #b83006;
  }

  .Research-Image-Counseling {
    width: 100%;
    height: 300px;
    /* background-image: url(../images/Toddlers.jpg); */
    background-size: cover;
    border-radius: 10px;
    background-image: url(../images/Counseling.jpg);
  }

  .sectionTwo {
    margin: auto;
    height: auto;
    width: 80%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    margin-top: 50px;
    z-index: 1;
    border-radius: 10px;

    box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: -1px 21px 27px 3px rgba(0, 0, 0, 0.27);
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .section-block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  .image {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }

  .section-heading {
    margin: 0;
    margin-top: 5px;
  }

  .body-text {
    font-size: 14px;
    width: 90%;
  }
}
