.nav {
  width: 100%;
  height: 10vh;

  background-color: rgb(255, 255, 255);
  display: flex;
  border-bottom: solid 5px #ff5722;
  padding-top: 5px;
}

.nav-logo {
  width: 50%;
  display: flex;
  justify-content: center;
}

.logo {
  height: 90%;
}

.nav-links {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}

.nav-link {
  margin-right: 15px;
  text-align: center;
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 14px;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 0px 20px;
}

.nav-link.active {
  background-color: #ff5722;
}

.nav-link:hover {
  background-color: #ff5722;
}

@media (max-width: 800px) {
  .nav {
    height: auto;

    align-items: center;
    justify-content: center;
  }

  .nav-logo {
    width: 35%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .logo {
    width: calc(90%);
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    width: 85%;
    background-color: #333;
    text-align: center;
    align-items: center;
    justify-content: start;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }

  .nav-links.open {
    display: flex;
    top: 0;
    width: 85%;
    height: 100%;
    align-items: flex-start;
    transition: height 0.3s ease;
    transition-delay: 0.1s;
    align-items: center;
    position: fixed;
  }

  .nav-links a {
    color: white;
    width: 90%;
    margin-top: 10px;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
  }

  .nav-mobile-toggle {
    position: absolute;
    right: 20px;
    color: black;
    cursor: pointer;
  }

  .burger {
    flex-direction: column;
    cursor: pointer;
    transition: 0.3s;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    margin: 5px 0;
    transition: 0.4s;
  }

  .burger.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .burger.open .bar:nth-child(2) {
    opacity: 0;
  }

  .burger.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
